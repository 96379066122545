import React from 'react'
import Img from 'gatsby-image'
import { Link } from '@elements'

export const FiftyFifty = ({
  body,
  children,
  className,
  ctaText,
  eyebrow,
  flip,
  fluid,
  heading,
  to,
}) => {
  return (
    <section className={className}>
      <div className="container">
        <div className="grid gap-6 sm:gap-8 lg:gap-12 max-w-lg lg:max-w-none mx-auto lg:mx-0 lg:grid-cols-2">
          <div
            className={`flex flex-col items-start justify-center max-w-lg ${
              flip ? `lg:ml-12` : `lg:ml-5`
            }`}
          >
            <h3 className="uppercase tracking-tight text-gray-300 font-bold text-lg sm:text-xl">
              {eyebrow}
            </h3>
            <h2 className="text-4xl sm:text-5xl leading-tight font-bold capitalize text-blue-400">
              {heading}
            </h2>
            {body && (
              <p className="mt-6 text-sm sm:text-lg text-gray-400">{body}</p>
            )}
            {children && (
              <div className="mt-6 text-sm sm:text-lg text-gray-400">
                {children}
              </div>
            )}
            {ctaText && (
              <Link to={to} className="btn mt-8 sm:mt-12 text-lg sm:text-xl">
                {ctaText}
              </Link>
            )}
          </div>
          {fluid && (
            <div
              className={`overflow-hidden shadow rounded order-first ${
                flip ? '' : 'lg:order-none'
              }`}
            >
              <Img fluid={fluid} className="h-full w-full" />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
