import React from 'react'
import AngiesList from '@images/angies-list.svg'
import SuperService from '@images/super-service.svg'

export const SocialProof = () => {
  return (
    <section className="py-10 bg-white">
      <div className="container">
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-10">
          <div className="flex items-center justify-center lg:justify-start lg:ml-5 lg:mt-5">
            <h2 className="text-md sm:text-2xl uppercase text-gray-400 font-bold border-b-2 sm:border-b-4 border-blue-100 pb-1 sm:pb-2">
              recognition
            </h2>
          </div>
          <div className="flex items-center justify-center">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.angieslist.com/companylist/us/in/indianapolis/updike-bathroom-remodeling-reviews-58122.htm"
            >
              <img src={AngiesList} alt="Angies list logo" />
            </a>
          </div>
          <div className="flex items-center justify-center">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.angieslist.com/companylist/us/in/indianapolis/updike-bathroom-remodeling-reviews-58122.htm"
            >
              <img src={SuperService} alt="Angies list super service badge" />
            </a>
          </div>
          <div className="flex items-center justify-center">
            <a
              href="https://www.bbb.org/us/in/indianapolis/profile/bathroom-remodel/updike-bathroom-remodeling-0382-22125/#sealclick"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://seal-indy.bbb.org/seals/darkgray-seal-293-61-bbb-22125.png"
                style={{ border: 0, filter: `brightness(155%)` }}
                alt="Updike Bathroom Remodeling BBB Business Review"
              />
            </a>
            {/* <Link to="/">
              <img src={BBB} alt="Better Business Accredited business badge" />
            </Link> */}
          </div>
        </div>
      </div>
    </section>
  )
}
