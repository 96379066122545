import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import { Link } from '@elements'

export const Hero = ({ eyebrow, title, ctaText, ctaLink, fluid }) => {
  return (
    <BackgroundImage Tag="section" fluid={fluid} backgroundColor={`#0C1159`}>
      <div className="absolute top-0 right-0 bottom-0 left-0 bg-blue-500 opacity-50 z-10"></div>
      <div className="mx-auto py-16 sm:py-20 md:py-32 lg:py-40 xl:py-48 z-20 relative max-w-md md:max-w-2xl lg:max-w-4xl xl:max-w-5xl text-white text-center">
        <h2 className="uppercase font-bold text-sm sm:text-base md:text-xl xl:text-2xl leading-tight">
          {eyebrow}
        </h2>
        <h1 className="mt-5 font-serif text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl leading-tight">
          {title}
        </h1>
        {ctaText && (
          <Link
            className="btn mt-8 md:mt-10 lg:mt-12 xl:mt-16 text-md md:text-xl"
            to={ctaLink}
          >
            {ctaText}
          </Link>
        )}
      </div>
    </BackgroundImage>
  )
}
