import React from 'react'
import { Link } from '@elements'

export const CTA = ({
  className = 'py-10 text-center',
  ctaText,
  heading,
  to,
}) => {
  return (
    <div className={className}>
      <h2 className="text-4xl text-blue-400 font-bold leading-tight">
        {heading}
      </h2>
      <Link to={to} className="btn text-lg sm:text-xl mt-6">
        {ctaText}
      </Link>
    </div>
  )
}
