import React from 'react'

export const WriteAReviewCallout = () => {
    return (
        <section className="py-10 lg:py-16 pb-24 lg:pb-32">
            <div className="text-center flex flex-col items-center">
                <div className="max-w-lg mx-auto">
                    <h2 className="text-4xl font-bold text-blue-400 lg:text-5xl">
                        Write a Review
                    </h2>
                    <p className="mt-4 text-sm text-gray-400 lg:text-lg">
                        Share your experience—we value your feedback!
                    </p>
                </div>
                <div className="max-w-sm lg:flex lg:max-w-3xl gap-5 mt-4 lg:mt-10">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.angi.com/write-review/58122"
                        className="w-full mt-6 btn whitespace-no-wrap"
                    >
                        Leave an Angie's List review
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://search.google.com/local/writereview?placeid=ChIJQ9mD2Y9ca4gRIuomHV2npFM"
                        className="w-full mt-6 btn whitespace-no-wrap"
                    >
                        Leave a Google review
                    </a>
                </div>
            </div>
        </section>
    )
}