import React from 'react'

export const Testimonial = ({ name, location, content }) => {
  return (
    <blockquote className="flex flex-col justify-between relative bg-white max-w-xs mx-auto overflow-hidden rounded shadow px-12 pt-16 pb-10 text-blue-400 leading-snug border-b-8 border-blue-200">
      <div className="flex-grow flex flex-col justify-center">
        <p className="relative">
          <span
            className="absolute z-10 font-serif text-gray-100 transform scale-150 text-6xl -translate-x-6 -translate-y-4"
            role="presentation"
          >
            “
          </span>
          <span className="relative z-20">{content}</span>
        </p>
        <div className="py-8">
          <hr />
        </div>
      </div>
      <footer className="mt-3 mb-1">
        <cite className="block not-italic font-bold text-2xl text-blue-300">
          {name}
        </cite>
        <cite className="block not-italic uppercase text-gray-400 text-sm">
          {location}
        </cite>
      </footer>
    </blockquote>
  )
}
