import React from 'react'
import Img from 'gatsby-image'

export const Blockquote = ({ className = 'py-10', children, fluid }) => {
  return (
    <section className={className}>
      <div className="container">
        <div className="flex flex-wrap sm:items-center">
          {fluid && (
            <div className="h-64 sm:h-56 md:h-64 w-3/4 sm:w-1/3 lg:w-1/4 xl:w-1/5 overflow-hidden rounded shadow mx-auto xl:transform xl:scale-y-125 xl:scale-x-110">
              <Img fluid={fluid} alt="towels" className="w-full h-full" />
            </div>
          )}
          <div className="mt-10 sm:mt-0 w-full sm:w-2/3 lg:w-3/4 xl:w-4/5 sm:pl-12 xl:pl-16">
            <blockquote className="text-center sm:text-left">
              <span className="inline-block text-6xl text-blue-200 leading-none font-serif transform scale-150">
                “
              </span>
              <p className="-mt-4 text-lg lg:text-2xl xl:text-3xl text-blue-500 font-bold tracking-wide leading-normal">
                {children}
              </p>
            </blockquote>
          </div>
        </div>
      </div>
    </section>
  )
}
