import React from 'react'
import { useWindowWidth } from '@hooks'
import { Link } from '@elements'
import { Testimonial } from './Testimonial'

export const Testimonials = ({ data }) => {
  let width = useWindowWidth()
  let cardCount = width >= 640 && width < 1024 ? 2 : 3
  const testimonials = data.slice(0, cardCount)
  return (
    <section className="testimonials-bg bg-cover bg-repeat-y bg-gray-100 py-10 sm:py-12 lg:py-20">
      <div className="container">
        <div className="text-center">
          <h2 className="text-blue-400 text-4xl lg:text-5xl capitalize font-bold">
            testimonials
          </h2>
          <p className="mt-1 text-base text-gray-400 leading-snug max-w-sm mx-auto lg:max-w-none text-lg">
            We value your feedback. Read what our customers have to say.
          </p>
        </div>
        <div className="mt-8 lg:mt-20 grid gap-8 sm:gap-5 sm:grid-cols-2 lg:grid-cols-3 max-w-5xl mx-auto">
          {testimonials.map(testimonial => (
            <Testimonial
              key={testimonial.name}
              name={testimonial.name}
              location={testimonial.location}
              content={testimonial.content}
            />
          ))}
        </div>
        <div className="flex items-center justify-center pt-10 lg:pt-20 pb-5">
          <Link to="/testimonials/" className="btn text-lg sm:text-xl">
            Read More
          </Link>
        </div>
      </div>
    </section>
  )
}
